
import { Component } from 'vue-property-decorator';
import MessagesMixin from '@/mixins/messages-mixin';
import MessagesToolbar from './MessagesToolbar.vue';

  @Component({
    mixins: [MessagesMixin],
    components: {
      MessagesToolbar
    }
  })
export default class MessagesBottomToolbar extends MessagesToolbar {
}
